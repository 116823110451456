
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class List extends Vue {
      private page = 1
      private size = 10
      private total = 0
      private loading = false
      private tableData = []
      private dateRange: Array<string> = []
      private buildList = []
      private roomList = []
      private state = ['手动', '定时', '智能']
      private searchInfo = {
        buildingId: this.$route.query.buildingId || '',
        roomName: '',
        deviceNumber: this.$route.params.deviceNumber || '',
        startDate: '',
        endDate: ''
      }

      created () {
        this.getBuildList()
        this.loadData()
        if (this.searchInfo.buildingId) {
          this.changeBuild()
        }
      }

      // 楼栋
      getBuildList () {
        this.$axios.get(this.$apis.smartHouse.selectBuildingByList).then((res) => {
          this.buildList = res.list || []
        })
      }

      changeBuild () {
        this.searchInfo.roomName = ''
        if (this.searchInfo.buildingId) {
          this.getRoomList()
        } else {
          this.roomList = []
        }
      }

      // 房间
      getRoomList () {
        this.$axios.get(this.$apis.smartHouse.selectRoomByList, { buildingId: this.searchInfo.buildingId }).then((res) => {
          this.roomList = res.list || []
        })
      }

      // 获取表格
      loadData () {
        this.loading = true
        this.$axios.get(this.$apis.smartHouse.selectSqIrrigationValveRecordByPage, {
          page: this.page,
          size: this.size,
          ...this.searchInfo
        }).then(res => {
          this.tableData = res.list
          this.total = res.total
        }).finally(() => {
          this.loading = false
        })
      }

      dateChange (date: string[]) {
        if (date) {
          this.searchInfo.startDate = date[0]
          this.searchInfo.endDate = date[1]
        } else {
          this.searchInfo.startDate = ''
          this.searchInfo.endDate = ''
        }
      }

      // 查询
      onSearch () {
        this.page = 1
        this.loadData()
      }
}
